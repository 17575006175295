export const environment = {
  production: false,
  environmentName: 'qa',
  // baseURL: 'https://api.qa.leciot.net',
  baseURL: 'https://5iwwt6lzm6.execute-api.eu-central-1.amazonaws.com/qa',
  loginURL:
    'https://5iwwt6lzm6.execute-api.eu-central-1.amazonaws.com/qa/c/authenticate',
  loginClientId: '2m1pfaga6vdd557fnl7lbn1ljq',
  loginClientSecret: '1r152pm64c1ojfdi9acnqpjlop0kl8kocl3kij3qj988p6cem4t8',
  loginScope: 'repair', // 'app',
  loginGrantType: 'client_credentials',
  loginAudience: 'ToDo',
  repairURL: '/c/cm/repair/spareparts',
  repairServiceURL: '/c/cm/repair/pcba',
  getUsersURL: '/c/cm/repair/user-management',
  manageUserURL: '/c/cm/repair/user-management',
  getUserScopeURL: '',
};
